import {yupResolver} from '@hookform/resolvers/yup';
import {utils} from 'ethers';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as yup from 'yup';

import {ArticlePageRegisterFormLight} from '../../../api/hooks/useArticlePage.hook';
import {useGlobal} from '../../../api/hooks/useGlobal.hook';
import {useRegisterPage as useRegisterPage} from '../../../api/hooks/useRegisterPage.hook';
import {CoverArt} from '../../../api/models/cover-art';
import {InstanceStatus} from '../../../api/models/instance-status';
import {RootState, useAppSelector} from '../../../redux';
import {connectToMetaMask} from '../../../redux/actions/blockchain.action';
import {
    buyCreatoken,
    fetchCreatokenVariants,
    resetBuyCreatokenState,
    setTransactionHash
} from '../../../redux/slices/creatoken-slice';
import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {MediaContentType} from '../../../utils/_enums/image-content-type.enum';
import {SalesType} from '../../../utils/_enums/sales-type';
import {ToasterTypes} from '../../../utils/_enums/toaster-types.enum';
import {EnumToLanguageMapper} from '../../../utils/_helper/enum-to-language-mapper';
import {mapStrapiHeadline} from '../../../utils/_helper/strapi-mapper';
import truncateString from '../../../utils/_helper/truncate-string-helper';
import {isValidGeoIp as isValidCountry} from '../../../utils/_helper/valid-country-helper';
import BasicCard from '../../_shared/basic-card/basic-card';
import {Button} from '../../_shared/button/button';
import {Checkbox} from '../../_shared/checkbox/checkbox';
import Dialog from '../../_shared/dialog/dialog';
import {Input} from '../../_shared/input-fields/input/input';
import InstanceCard from '../../_shared/instance-card/instance-card';
import {LinkBase} from '../../_shared/link/link-base/link-base';
import Media from '../../_shared/media/media';
import {Select} from '../../_shared/select/select';
import {Spacer} from '../../_shared/spacer/spacer';
import Teaser from '../../_shared/teaser/teaser';
import TextSection from '../../_shared/text-section/text-section';
import {Typography} from '../../_shared/typography/typography';
import styles from './register-form-light.module.scss';

interface AuthenticationFormData extends FormData {
    email: string;
    agbConfirm: boolean;
    expirationRightOfWithdrawal: boolean;
    country: string;
    newsletter: boolean;
    inverted?: boolean;
}

export const RegisterFormLight: React.FC<ArticlePageRegisterFormLight> & {
    dataHooks: any;
} = (
    props: ArticlePageRegisterFormLight
) => {
    const registerPageData = useRegisterPage();

    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const [selectedVariant, setSelectedVariant] = useState<{
        id: number;
        price: number;
        tier: number;
        coverArt: CoverArt;
    } | undefined>(undefined);
    const [submittedData, setSubmittedData] = useState<AuthenticationFormData>();

    const {
        buyCreatokenModel,
        transactionHash,
        creatokenVariantsModel
    } = useAppSelector((state: RootState) => state.creatokenSlice);
    const {
        walletId,
        isMetaMaskInstalled,
        chainId,
        pending
    } = useAppSelector((state) => state.blockchainSlice);
    const countryOptions = EnumToLanguageMapper.countriesMapping().filter(
        ({value}) => isValidCountry(value.toLowerCase())
    );

    const data = useGlobal();

    useEffect(() => {
        dispatch(fetchCreatokenVariants());
    }, []);

    useEffect(() => {
        if (buyCreatokenModel.status && buyCreatokenModel.status !== 200) {
            dispatch(resetBuyCreatokenState());
        }
    }, [buyCreatokenModel.status]);

    const schema = yup.object({
        country: yup
            .string()
            .required(
                registerPageData?.country.fieldErrorMessages?.required ?? ''
            ),
        email: yup
            .string()
            .required(
                registerPageData?.email.fieldErrorMessages?.required ?? ''
            )
            .email(registerPageData?.email.fieldErrorMessages?.valid ?? ''),
        agbConfirm: yup
            .boolean()
            .oneOf(
                [true],
                registerPageData?.agbAdvancedAccept?.fieldErrorMessages?.required ?? ''
            ),
        expirationRightOfWithdrawal: yup
            .boolean()
            .oneOf(
                [true],
                props?.expirationRightOfWithdrawalRequired ?? ''
            ),
    });

    const resolver = yupResolver(schema);
    const {
        register,
        handleSubmit,
        control,
        formState: {errors, isSubmitted}
    } = useForm<AuthenticationFormData>({
        resolver,
        mode: 'onChange'
    });

    useEffect(() => {
        if (submittedData && walletId && selectedVariant && chainId) {
            if (chainId !== Number(process.env.NEXT_PUBLIC_CHAIN_ID)) {
                addToast(props?.invalidEthereumNetwork, {
                    appearance: ToasterTypes.ERROR
                });
            } else {
                dispatch(buyCreatoken({
                    backendData: {...submittedData, variant: selectedVariant.tier, wallet: walletId},
                    contractData: {
                        walletId: walletId,
                        price: utils.parseEther(selectedVariant.price.toString()).toString(),
                        tier: selectedVariant.tier
                    }
                }));
            }
            setSubmittedData(undefined);
        }
    }, [submittedData, walletId, chainId]);

    const dataSubmitted = (data: AuthenticationFormData) => {
        setSubmittedData(data);
        dispatch(connectToMetaMask());
    };

    const renderVariants = () => {
        if (
            creatokenVariantsModel.data &&
            creatokenVariantsModel.data.length > 0
        ) {
            return (
                <Teaser
                    onLoadNewItems={() => undefined}
                    maxCards={6}
                    headline={props.teaserHeadline?.headlineContent}
                    headlineElement={props.teaserHeadline?.element}
                    headlineVariant={mapStrapiHeadline(props.teaserHeadline?.style)}
                    headlineStyle={props.teaserHeadline?.textAlign}
                    withNumbers={false}
                    errorMessage={!selectedVariant && isSubmitted
                        ? props.noVariantSelectedMsg : ''
                    }
                    type={BasicCardVariants.CREATOKEN_CARD}
                    pagination={{
                        page: 0,
                        size: 6
                    }}
                    inverted={props.inverted}
                    hideTeaserButtons={true}
                    cards={creatokenVariantsModel.data.map((variant) => (
                        <BasicCard
                            key={variant.id}
                            inverted={props.inverted}
                            media={{
                                contentType: variant.coverArt?.contentContentType,
                                mediaUrl: variant?.coverArt?.url,
                                posterUrl: variant.previewImage,
                                isTeaser: true
                            }}
                            disabled={variant.purchasableInstances <= 0}
                            selected={variant.id === selectedVariant?.id}
                            onClick={() => {
                                setSelectedVariant({
                                    id: variant.id,
                                    price: variant.salesAttribute.price,
                                    tier: variant.tier ?? -1,
                                    coverArt: variant.coverArt
                                });
                            }}
                            type={BasicCardVariants.CREATOKEN_CARD}
                        >
                            <InstanceCard
                                text={{
                                    headline: variant.title,
                                    topline: data?.brand?.creatokia ?? '',
                                    availableCopies: variant.purchasableInstances,
                                    copies: variant.purchasableInstances + variant.instancesSold
                                }}
                                inverted={props.inverted}
                                footer={{
                                    purchasable: {
                                        ethPrice: variant.salesAttribute.price,
                                        ownerId: 1,
                                        status: InstanceStatus.ALL
                                    },
                                    salesType: SalesType.FIXED_PRICE
                                }}
                                type={BasicCardVariants.CREATOKEN_CARD}
                            />
                        </BasicCard>
                    ))}
                />
            );
        }
    };

    return (
        <div>
            <form
                noValidate
                className={`${styles['authentification-form']} ${
                    props?.inverted ? styles['inverted'] : ''
                }`}
                onSubmit={handleSubmit(dataSubmitted)}
            >
                {renderVariants()}
                <div
                    className={
                        props?.teaserHeadlineAndParagraphHeadline &&
                        props?.teaserHeadlineAndParagraphParagraph
                            ? styles['wrapper']
                            : ''
                    }
                >
                    <div
                        style={
                            props?.teaserHeadlineAndParagraphHeadline &&
                            props?.teaserHeadlineAndParagraphParagraph
                                ? {
                                    flex: '1'
                                }
                                : undefined
                        }
                    >
                        <Input
                            key="email-input"
                            register={register}
                            title={registerPageData?.email?.fieldLabel}
                            type="email"
                            name="email"
                            placeholder={registerPageData?.email?.fieldHint}
                            errorMessage={(errors as any)?.['email']?.message}
                        />
                        <Spacer height="20px"/>
                        <Select
                            inverted={props?.inverted}
                            key="country-input"
                            control={control}
                            label={registerPageData?.country?.fieldLabel}
                            name="country"
                            placeholder={registerPageData?.country?.fieldHint}
                            errorMessage={(errors as any)?.['country']?.message}
                            options={countryOptions}
                        />
                        <Spacer height="20px"/>
                        <Checkbox
                            register={register}
                            key="agb-checkbox"
                            name="agbConfirm"
                            errorMessage={
                                (errors as any)?.['agbConfirm']?.message
                            }
                            inverted={props.inverted}
                        >
                            {registerPageData?.agbAdvancedAccept?.fieldLabel}
                        </Checkbox>
                        <Spacer height="20px"/>
                        <Checkbox
                            name={'expirationRightOfWithdrawal'}
                            errorMessage={
                                (errors as any)?.['expirationRightOfWithdrawal']
                                    ?.message
                            }
                            register={register}
                            inverted={props.inverted}
                        >
                            {props?.expirationRightOfWithdrawal}
                        </Checkbox>
                        <Spacer height="20px"/>
                        <Checkbox
                            register={register}
                            key="newsletter-checkbox"
                            name="newsletter"
                            inverted={props.inverted}
                        >
                            {registerPageData?.newsletterAccept?.fieldLabel}
                        </Checkbox>
                        <Spacer height="20px"/>
                        <Button
                            inverted={props?.inverted}
                            disabled={!isMetaMaskInstalled}
                            pending={pending || buyCreatokenModel.pending}
                        >
                            {props.buyButton}
                        </Button>
                        <Spacer height="30px"/>
                        <Typography inverted={props?.inverted}>
                            {isMetaMaskInstalled ? '' : props?.metaMaskNotInstalled?.content}
                        </Typography>
                    </div>
                    {props?.teaserHeadlineAndParagraphHeadline &&
                    props?.teaserHeadlineAndParagraphParagraph && (
                        <>
                            <Spacer width="100px"/>
                            <div
                                className={styles['legal-wrapper']}
                            >
                                <Typography
                                    className={
                                        props?.inverted
                                            ? styles['white-text']
                                            : undefined
                                    }
                                    disableMarkdown
                                    variant={mapStrapiHeadline(
                                        props
                                            ?.teaserHeadlineAndParagraphHeadline
                                            ?.style
                                    )}
                                >
                                    {props
                                        ?.teaserHeadlineAndParagraphHeadline
                                        ?.headlineContent ?? ''}
                                </Typography>
                                <Spacer height="10px"/>
                                <Typography
                                    className={
                                        props?.inverted
                                            ? styles['grey-text']
                                            : undefined
                                    }
                                >
                                    {props
                                        ?.teaserHeadlineAndParagraphParagraph
                                        ?.content ?? ''}
                                </Typography>
                            </div>
                        </>
                    )}
                </div>
            </form>
            {transactionHash && (
                <Dialog onClose={() => dispatch(setTransactionHash(undefined))}>
                    <TextSection
                        title={props?.buyDialogHeadline?.headlineContent}
                        description={props?.buyDialogDescription?.content}

                    />
                    <div className={styles['bottom-wrapper']}>
                        <Spacer height={'20px'}/>
                        <Media
                            alt={selectedVariant?.coverArt?.name}
                            src={selectedVariant?.coverArt?.url}
                            contentType={MediaContentType.MP4}
                            className={styles['dialog-image']}
                        />
                        <Spacer height={'20px'}/>
                        <LinkBase
                            href={`https://${
                                Number(process.env.NEXT_PUBLIC_CHAIN_ID) === 5
                                    ? 'goerli.'
                                    : ''
                            }etherscan.io/tx/${transactionHash}`}
                            external={true}
                        >
                            {`https://${
                                Number(process.env.NEXT_PUBLIC_CHAIN_ID) === 5
                                    ? 'goerli.'
                                    : ''
                            }etherscan.io/tx/${truncateString(
                                transactionHash,
                                6,
                                4
                            )}`}
                        </LinkBase>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

RegisterFormLight.dataHooks = [useRegisterPage];
