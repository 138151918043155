import {StrapiMeta} from '../models/strapi/strapi-meta';
import {StrapiSelectFieldProps} from '../models/strapi/strapi-select-field-props';
import {useLanguageDependentDataSSr} from './useLanguageDependentDataSsr.hook';

export const useAccountSupportAndContact =
    useLanguageDependentDataSSr<ContactAndSupportData>('account-support-and-contact');

interface ContactAndSupportData {
    id: number;
    headline: string;
    description: string;
    username: string;
    emailAddress: string;
    subHeadline: string;
    formDescription: string;
    faqHeadline: string;
    faqDescription: string;
    formHeadline: string;
    toTheFAQs: string;
    faqURL: string;
    mailSendSuccessfully: string;
    send: string;
    placeholder: string;
    meta: StrapiMeta;
    topic: StrapiSelectFieldProps;
    savedSuccessfully: string;
    savedError: string;
}
