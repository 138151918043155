import { createDataHook } from 'next-data-hooks';

import { backend } from '../../utils/_config/axios';
import { BasePageData } from '../models/strapi/base-page-data';
import { StrapiBooleanFieldProps } from '../models/strapi/strapi-boolean-field-props';
import { StrapiButtonProps } from '../models/strapi/strapi-button-props';
import { StrapiFaqContainer } from '../models/strapi/strapi-faq-container';
import { StrapiFaqItem } from '../models/strapi/strapi-faq-item';
import { StrapiLinkProps } from '../models/strapi/strapi-link-props';
import { StrapiMeta } from '../models/strapi/strapi-meta';
import { StrapiStage } from '../models/strapi/strapi-stage';
import { StrapiTextFieldProps } from '../models/strapi/strapi-text-field-props';
import { StrapiTextSectionProps } from '../models/strapi/strapi-text-section-props';

export const useRegisterPage = createDataHook(
    'authentification-form',
    async ({ locale }) => {
        const localeRes = !locale || locale === 'catchAll' ? 'en' : locale;
        const result = (
            await backend().get<UseAuthentificationPageData>(
                `api/strapi/authentification-form?_locale=${localeRes}`
            )
        ).data;

        result.faqbox = await Promise.all(
            result.faqbox.map(async (box) => {
                box.fa_qs = await Promise.all(
                    box.fa_qs?.map(async (faqIdItem) => {
                        const { id } = (faqIdItem as unknown) as { id: number };

                        return (await backend().get<StrapiFaqItem>(`api/strapi/fa-qs/${id}`))
                            .data;
                    })
                );
                return box;
            })
        );

        return result;
    }
);

interface UseAuthentificationPageData extends BasePageData {
    loginHeader: StrapiTextSectionProps;
    loginLinkText: string;
    registerHeader: StrapiTextSectionProps;
    email: StrapiTextFieldProps;
    username: StrapiTextFieldProps;
    walletId: StrapiTextFieldProps;
    password: StrapiTextFieldProps;
    confirmPassword: StrapiTextFieldProps;
    emailOrUsername: StrapiTextFieldProps;
    loginButton: StrapiButtonProps;
    registerButton: StrapiButtonProps;
    agbAdvancedAccept: StrapiBooleanFieldProps;
    dropletterAccept: StrapiBooleanFieldProps;
    newsletterAccept: StrapiBooleanFieldProps;
    forgotPasswordLink: StrapiLinkProps;
    createWalletLink: StrapiLinkProps;
    readWalletLink: StrapiLinkProps;
    faqbox: StrapiFaqContainer[];
    stage: StrapiStage;
    meta: StrapiMeta;
    country: StrapiTextFieldProps;
    noUsernameError: string;
    noMailError: string;
    noPasswordError: string;
    noAGBError: string;
    usernameOrEmailExistsError: string;
    noCountryError: string;
    unknownError: string;
    registerFormText: StrapiTextSectionProps;
    registerThankYouURL: string;
}
