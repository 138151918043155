import React from 'react';

import { useTempHeader } from '../../../../api/hooks/useTempHeader.hook';
import { DefaultHeaderMobileLoginFormContextEnum } from '../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';
import { RoutePath } from '../../../../utils/_enums/route-path.enum';
import { useArticlePageTempHeaderContext } from '../../../../utils/_hooks/useArticlePageTempHeaderContext.hook';
import { LanguageSelectorOptions } from '../../../../utils/_models/languageSelectorOptions';
import { HeaderMobileNavigation } from '../../../_shared/header/header-mobile/header-mobile-navigation/header-mobile-navigation';
import styles from './article-page-mobile-navigation.module.scss';

interface ArticlePageTempHeaderMobileNavigationProps {
    localization?: LanguageSelectorOptions[];
    forStaticPage?: boolean;
    pageType?: string;
}

export const ArticlePageTempHeaderMobileNavigation: React.FC<ArticlePageTempHeaderMobileNavigationProps> = (props: ArticlePageTempHeaderMobileNavigationProps) => {
    const { mobileNavOpen, dispatch } = useArticlePageTempHeaderContext();

    const { navLinks } = useTempHeader();

    return (
        <HeaderMobileNavigation
            className={styles['default-header-mobile-navigation']}
            open={mobileNavOpen}
            localizations={props.localization}
            forStaticPage={props.forStaticPage}
            pageType={props.pageType}
            onCancelClick={() =>
                dispatch({
                    type:
                        DefaultHeaderMobileLoginFormContextEnum.TOGGLEMOBILENAVIGATION
                })
            }
            navLinks={
                navLinks?.map(({external, label, link}) => ({
                    label,
                    value: link as RoutePath,
                    external
                })) ?? []
            }
        ></HeaderMobileNavigation>
    );
};
