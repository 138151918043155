import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as yup from 'yup';

import {useAccountSupportAndContact} from '../../../api/hooks/useAccountSupportAndContact';
import {useCurrentLanguage} from '../../../api/hooks/useCurrentLanguage.hook';
import {ContactFormData} from '../../../api/models/contact-form-data';
import {removeSupportStatus, RootState, sendContactForm, useAppSelector} from '../../../redux';
import {ToasterTypes} from '../../../utils/_enums/toaster-types.enum';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {Button} from '../button/button';
import {Input} from '../input-fields/input/input';
import TextArea from '../input-fields/text-area/text-area';
import {Select} from '../select/select';
import {Typography} from '../typography/typography';
import styles from './contact-form.module.scss';

interface ContactFormProps {
    inverted?: boolean;
}

const ContactForm = (props: ContactFormProps): JSX.Element => {
    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const {user, supportModel} = useAppSelector((state: RootState) => ({
        user: state.userSlice.user,
        supportModel: state.supportSlice.supportModel
    }));
    const data = useAccountSupportAndContact();
    const schema = yup.object({
        email: yup.string().required().email(),
        message: yup.string().required(),
        topic: yup.string().required()
    });

    const {executeRecaptcha} = useGoogleReCaptcha();

    const language = useCurrentLanguage();

    const resolver = yupResolver(schema);
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm<ContactFormData>({
        resolver,
        mode: 'onChange'
    });

    useEffect(() => {
        return () => {
            dispatch(removeSupportStatus());
        };
    }, []);

    useEffect(() => {
        reset({topic: 'technicalProblem', email: user?.email});
    }, [data]);

    useEffect(() => {
        if (data) {
            switch (supportModel.status) {
                case 200:
                    addToast(data.mailSendSuccessfully, {
                        appearance: ToasterTypes.SUCCESS
                    });
                    reset({message: '', topic: 'technicalProblem'});
                    break;
            }
        }
    }, [supportModel.status]);

    const contactUser: SubmitHandler<ContactFormData> = (form) => {
        if (executeRecaptcha) {
            const token = executeRecaptcha('contact_page');
            token.then((res) => {

                dispatch(
                    sendContactForm({
                        ...form,
                        language: language.toUpperCase() as 'DE' | 'EN',
                        username: user ? user.username : '',
                        token: res
                    })
                );

            });
        }
    };

    if (data) {
        return (
            <div
                className={styles['support-form-wrapper']}
                data-testid="contact-form"
            >
                <Typography
                    element="h3"
                    inverted={props.inverted}
                    variant={TypographyVariantsEnum.HEADING4_BOLD}
                    className={styles['form-headline']}
                >
                    {data.formHeadline}
                </Typography>
                <Typography inverted={props.inverted}>
                    {data.formDescription}
                </Typography>
                <form
                    className={styles['contact-form']}
                >
                    <div className={styles['contact-form-input-wrapper']}>
                        <div className={styles['left-inputs']}>
                            <Select
                                className={styles['topic']}
                                placeholder={data.topic?.fieldPlaceholder}
                                name={'topic'}
                                options={data.topic?.fieldSelectionOptions}
                                control={control}
                                label={data.topic?.fieldLabel}
                                isSearchable={false}
                                inverted={props.inverted}
                                errorMessage={errors?.['topic']?.message}
                            />
                            <Input
                                name={'email'}
                                title={data.emailAddress}
                                register={register}
                                placeholder={data.placeholder}
                                inverted={props.inverted}
                                errorMessage={errors?.['email']?.message}
                            />
                        </div>
                        <TextArea
                            name={'message'}
                            title={data.description}
                            className={styles['description-area']}
                            register={register}
                            inverted={props.inverted}
                            placeholder={data.placeholder}
                            errorMessage={errors?.['message']?.message}
                        />
                    </div>
                    <div className={styles['button-wrapper']}>
                        <Button
                            inverted={props.inverted}
                            onClick={handleSubmit(contactUser)}
                            pending={supportModel.pending}
                        >{data.send}</Button>
                    </div>
                </form>
            </div>
        );
    } else {
        return <div/>;
    }
};

export default ContactForm;

ContactForm.dataHooks = [useAccountSupportAndContact];
