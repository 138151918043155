import React from 'react';

import {useCardText} from '../../../api/hooks/useCardText.hook';
import {InstanceStatus} from '../../../api/models/instance-status';
import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {SalesType} from '../../../utils/_enums/sales-type';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import TeaserCardFooter from '../teaser-card-components/teaser-card-footer/teaser-card-footer';
import TeaserCardText from '../teaser-card-components/teaser-card-text/teaser-card-text';
import {Typography} from '../typography/typography';
import styles from './instance-card.module.scss';

export interface InstanceCardProps {
    text: {
        headline: string;
        topline: string;
        description?: string;
        optionalInfo?: string;
        serialNumber?: number;
        availableCopies?: number;
        copies?: number;
    };
    footer?: {
        purchasable?: {
            ownerId: number;
            price?: number;
            ethPrice: number;
            onBuy?: () => void;
            onBid?: () => void;
            status: InstanceStatus;
            buyButtonDisabled?: boolean;
            buyButtonPending?: boolean;
        };
        salesType: SalesType;
        additionalInfo?: string;
        readMore?: () => void;
    };
    inverted?: boolean;
    type: BasicCardVariants;
    redirectUrl?: string;
}

const InstanceCard: React.FC<InstanceCardProps> = (props) => {

    const data = useCardText();

    return (
        <div
            data-testid="instance-card"
            className={`${styles['instance-card-wrapper']} ${
                styles[`${props.type ? props.type : ''}`]
            }`}
        >
            {/* Optional text under the big image - right aligned */}
            {props.text.copies !== undefined &&
            (props.text.serialNumber !== undefined || props.text.availableCopies !== undefined) ? (
                <div className={styles['optional-info']}>
                    <Typography
                        variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                        inverted={props.inverted}
                    >
                        {props.text.availableCopies !== undefined
                            ? `${props.text.availableCopies} / ${props.text.copies}`
                            : `${data?.copy ?? ''} ${props.text.serialNumber ?? ''} / ${props.text.copies ?? ''}`}
                    </Typography>
                </div>
            ) : undefined}

            <div className={styles['teaser-card-text']}>
                {/* topline (Author name...), headline, description */}
                <TeaserCardText
                    {...props.text}
                    inverted={props.inverted}
                    variant={props.type}
                    redirectUrl={props.redirectUrl}
                />
            </div>

            {props.footer && (
                <TeaserCardFooter
                    {...props.footer}
                    inverted={props.inverted}
                    type={props.type}
                    redirectUrl={props.redirectUrl}
                />
            )}
        </div>
    );
};

export default InstanceCard;
