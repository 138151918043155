import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useTempHeader } from '../../../api/hooks/useTempHeader.hook';
import { ButtonVariantsEnum } from '../../../utils/_enums/button-variants.enum';
import { DefaultHeaderMobileLoginFormContextEnum } from '../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';
import { RoutePath } from '../../../utils/_enums/route-path.enum';
import { useArticlePageTempHeaderContext } from '../../../utils/_hooks/useArticlePageTempHeaderContext.hook';
import useBreakpoints from '../../../utils/_hooks/useBreakpoints.hook';
import { LanguageSelectorOptions } from '../../../utils/_models/languageSelectorOptions';
import { Header } from '../../_shared/header/header';
import { HeaderBackground } from '../../_shared/header/header-background/header-background';
import { ArticlePageTempHeaderMobileNavigation } from './article-page-mobile-navigation/article-page-mobile-navigation';
import { ArticlePageTempHeaderContextProvider } from './article-page-temp-context/article-page-temp-context';
import styles from './article-page-temp-header.module.scss';

interface Props {
    availableLanguages?: LanguageSelectorOptions[];
    forStaticPage?: boolean;
    pageType?: string;
}

const ArticlePageTempHeaderComponent: React.FC<Props> = ({
    forStaticPage,
    availableLanguages,
    pageType
}) => {
    const defaultHeaderData = useTempHeader();
    const { dispatch } = useArticlePageTempHeaderContext();
    const value = useBreakpoints();

    return (
        <header className={styles['article-page-temp-header']}>
            <HeaderBackground />
            <div>
                <Header
                    element="div"
                    inverted={true}
                    forStaticPage={forStaticPage}
                    languages={availableLanguages}
                    pageType={pageType}
                    navLinks={
                        defaultHeaderData?.navLinks?.map(({label, link, external}) => ({
                            label,
                            value: link as RoutePath,
                            external
                        })) ?? []
                    }
                    buttons={
                        value === 'mobile'
                            ? [
                                {
                                    variant:
                                        ButtonVariantsEnum.SECONDARY_OUTLINED,
                                    icon: {
                                        element: (
                                            <FontAwesomeIcon
                                                icon={['far', 'bars']}
                                            />
                                        ),
                                        align: 'right'
                                    },
                                    onClick: () => {
                                        dispatch({
                                            type:
                                                DefaultHeaderMobileLoginFormContextEnum.TOGGLEMOBILENAVIGATION
                                        });
                                    }
                                }
                            ]
                            : undefined
                    }
                />
                <ArticlePageTempHeaderMobileNavigation
                    localization={availableLanguages}
                    pageType={pageType}
                    forStaticPage={forStaticPage}
                />
            </div>
        </header>
    );
};

const WithContext: React.FC<Props> & { dataHooks: any } = (props) => {
    return (
        <ArticlePageTempHeaderContextProvider>
            <ArticlePageTempHeaderComponent {...props} />
        </ArticlePageTempHeaderContextProvider>
    );
};

export const ArticlePageTempHeader = WithContext;

WithContext.dataHooks = [useTempHeader];

WithContext.defaultProps = { availableLanguages: [] };
