import React, { Reducer, useReducer } from 'react';

import { DefaultHeaderMobileLoginFormContextEnum } from '../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';

interface ArticlePageTempHeaderContextData {
    mobileNavOpen: boolean;

    dispatch: React.Dispatch<{ type: DefaultHeaderMobileLoginFormContextEnum }>;
}

const initialData = {
    mobileNavOpen: false,

    dispatch: () => {
        alert('Error');
        return;
    }
};

const reducer: Reducer<
    ArticlePageTempHeaderContextData,
    { type: DefaultHeaderMobileLoginFormContextEnum }
> = (state = initialData, action) => {
    switch (action.type) {
        case DefaultHeaderMobileLoginFormContextEnum.TOGGLEMOBILENAVIGATION: {
            return {
                ...state,
                mobileNavOpen: !state.mobileNavOpen
            }
        }
        case DefaultHeaderMobileLoginFormContextEnum.RESET: {
            state.mobileNavOpen = false;
            return {
                ...state,
                mobileNavOpen: false
            }
        }
        default:
            return state;
    }
};

export const ArticlePageTempHeaderContext = React.createContext<ArticlePageTempHeaderContextData>(
    initialData
);

export const ArticlePageTempHeaderContextProvider: React.FC = ({
    children
}) => {
    const [state, dispatch] = useReducer(reducer, initialData);

    return (
        <ArticlePageTempHeaderContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ArticlePageTempHeaderContext.Provider>
    );
};
