import React from 'react';
import { UseFormRegister } from 'react-hook-form';

import { ErrorMessage } from '../error/error-message';
import { Typography } from '../typography/typography';
import styles from './checkbox.module.scss';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    children: string;
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: UseFormRegister<any>;
    errorMessage?: string;
    inverted?: boolean;
}

export const Checkbox: React.FC<Props> = ({
                                              name,
                                              children,
                                              register,
                                              errorMessage,
                                              inverted,
                                              className,
                                              ...inputProps
                                          }) => {
    return (
        <div
            data-testid='checkbox'
            className={`${className} ${styles['checkbox-wrapper']} ${inputProps.disabled ? styles['disabled'] : ''
            }`}
        >
            <label className={styles['container']}>
                <input {...register(name)} {...inputProps} type='checkbox' />
                <span
                    style={{
                        borderColor: inverted ? '#fff' : '#000'
                    }}
                    className={styles['checkmark']} />
                <Typography inverted={inverted}>
                    {children}
                </Typography>
            </label>
            <ErrorMessage className={styles['error']}>{errorMessage}</ErrorMessage>
        </div>
    );
};
