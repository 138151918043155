import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useTempHeader = useLanguageDependentDataSSr<UseTempHeaderData>(
    'temp-header'
);

interface UseTempHeaderData {
    navLinks: {
        label: string;
        link: string;
        external: boolean;
    }[];
}
